.suggestion {
  cursor: pointer;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}

.tag-input {
  width: 80%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

.suggestion:hover{
  background-color: gray;
}
